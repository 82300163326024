var jQuery = require("jquery")

global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

export const rails_ujs = require("@rails/ujs")
rails_ujs.start()
global.Rails = Rails;

require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require('packs/organizations')
require("select2")

import "bootstrap"
import "../stylesheets/logged_out.scss"

document.addEventListener("turbolinks:load", ()=>{
    $('[data-toggle="tooltip"]').tooltip()
    $('[data-toggle="popover"]').popover()
    $('#issue_tag_list').select2({
        theme: "bootstrap4",
        tags: true
    });
})


$(document).on("turbolinks:load", () => {
    $('[data-toggle="tooltip"]').tooltip()
    $('[data-toggle="popover"]').popover()

    $("select.select2:not(.select2-loaded, .tag-search)").addClass("select2-loaded").select2({ theme: "bootstrap4" });

    // special select2 for tags
    $("select.select2.tag-search:not(.select2-loaded)").addClass("select2-loaded").select2({ theme: "bootstrap4", ajax: { url: '/issues/tags.json', dataType: 'json'} });
});

$(document).on("turbolinks:before-cache", () => {
    $("select.select2.select2-loaded").removeClass("select2-loaded").select2("destroy");
});