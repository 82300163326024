$(document).on('turbolinks:load', function() {
  // alert("hello");
  $(".form-apply .email-input #organization_users_attributes_0_email").focusout(function(){
    let feedback_div = $(".email-input .invalid-feedback");
    var email = $(this).val();
    if(email.indexOf("@") > 0){
      var domain = email.split("@")[1];
      if(domain.toLowerCase() == $(".url-input #organization_url").val()){
        feedback_div.hide();
        $(".form-signin").find("input[type='submit']").prop('disabled', false)
      } else {
        feedback_div.show();
        $(".form-signin").find("input[type='submit']").prop('disabled', true)
      }
    }
  });

  // $(".form-signin .url-input #organization_url").focusout(function(){
  $(".form-apply #organization_url").focusout(function(){
    let feedback_div = $(".url-input .invalid-feedback")
    if (isValidUrl($(this).val())) {
      let text = $(this).val().toLowerCase()
      text = text.replace(" ", "").replace('http://','').replace('https://','').replace("www.", "").split("/")[0]
      $(this).val(text);
      feedback_div.hide();
      $(".form-signin").find("input[type='submit']").prop('disabled', false)
    } else {
      feedback_div.show();
      $(".form-signin").find("input[type='submit']").prop('disabled', true)
    }
  });

  $('.reason-field #organization_features_interested_other').change(function(){
    if ($(this).is(":checked")){
      $(".reason-field #other-input").prop('disabled', false);
    }
    else {
      $(".reason-field #other-input").prop('disabled', true);
    }
  });

});

function isValidUrl(urlString) {
  var urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
  '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
  '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
  '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
  return !!urlPattern.test(urlString);
}